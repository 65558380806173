.projectDetails_page {

  & .project_intro {margin-bottom: var(--space-xl-size);}

  & .project_principal_header {
    height: calc(var(--space-xl-size) * 2);
    display: flex;
    align-items: center;
    margin-bottom: var(--space-s-size);

    & h1 {text-align: center; width: 100%;}

  }

  & .project_credits {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: var(--space-s-size);

  }

  & .project_context {
    margin-bottom: var(--space-xl-size);
    display: flex;
    flex-wrap: wrap;
    row-gap: var(--space-l-size);

    & .context, .challenge {
      display: flex;
      flex-wrap: wrap;
      row-gap: var(--space-s-size);

    }

  }

  & .project_highlights {
    width: 100%;
    height: auto;

    & h3 {
      margin-bottom: var(--space-xl-size);

    }

  }

}

@media only screen and (min-width: 1024px) {

  .project_credits {
    justify-content: space-between;


  }

  & .project_context {

    & .context, .challenge {
      display: flex;
      flex-wrap: nowrap;
      row-gap: var(--space-s-size);
      justify-content: space-between;

      & h4 {

      }

      & p {
        width: 68.5%;

      }

    }

  }

}

