.background_blended {
  width: 100%;
  height: 12rem;
  background-color: rgb(255, 255, 255);
  /* mix-blend-mode: difference; */
  /* backdrop-filter: blur(5px); */
  position: absolute;
  top: 0;
  z-index: 10;
  opacity: 1;

}

.menuModify {
  padding-top: 33rem;
  transition: ease all 0.3s;

}

.menuInitial {
  transition: ease all 0.3s;

}

/* .layout {
  overflow: hidden;

} */

@media only screen and (min-width: 1024px) {

  .background_blended {
    position: fixed;

  }

  .menuModify {
    padding-top: 0;
    transition: ease all 0.3s;
  
  }

}