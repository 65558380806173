.main_button {
  width: 7rem;
	height: 7rem;
	border: 1px solid var(--main-principal-color);
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
  background-color: transparent;
  transition: ease all .5s;

  & img {
    width: 3rem;

  }

}