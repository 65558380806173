.video-container {
  position: relative;
  display: inline-block; /* Asegura que el contenedor se ajuste al tamaño del video */
  width: 100%;
  border: calc(var(--space-l-size) + var(--space-m-size)) solid var(--main-principal-color);
  cursor: pointer;

}

.video-container video {
  display: block;
  width: 100%;
}

.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #727272;
  z-index: 1;

  @media only screen and (max-width: 1024px) {
    display: none;

  }

}

.progress {
  height: 100%;
  background-color: var(--main-accent-color);
  width: 0; /* El ancho se actualizará dinámicamente */
  transition: ease all .3s;

  @media only screen and (max-width: 1024px) {
    display: none;

  }

}

.state-paused {
  position: absolute;
  bottom: calc(var(--space-m-size) - (var(--space-s-size) + var(--space-s-size) + var(--space-s-size)));
  color: var(--main-accent-color);
  font-family: 'Manrope-Regular', sans-serif;
  font-size: 2rem;
  left: var(--space-s-size);
  line-height: 24px;
  left: calc(var(--space-m-size) / 3);
  transition: ease all .5s;

  &::before {
    content: '';
    background-image: url('../Images/icon_video_pause.svg');
    height: 3rem;
    width: 3rem;
    position: absolute;
    left: -3rem;
    top: 0;
  }

}

@media only screen and (max-width: 1024px) {

  .video-container {
    border: 5rem solid;

  }

  .state-paused {
    font-size: 1.5rem;
    left: 2rem;
    bottom: -4rem;

  }

  &::before {
    height: 1rem;
    width: 1rem;

  }

}

@media only screen and (min-width: 1800px) {

  .state-paused {
    left: 2rem;
    bottom: -6rem;

  }

}

