.music_player {

  height: 10rem;
  display: flex;
  align-items: center;
  padding: 0 5rem;
  border: 1px solid black;
  justify-content: space-between;
  border-radius: 5rem;
  transition: ease all .5s;
  width: 100%;
  

  & .info_container {
   
    display: flex;
    align-items: center;
    gap: 2rem;
    width: 60%;
 
  }

  & .cover {
    height: 7rem;
    width: 7rem;
    object-fit: cover;

  }

  & .title {
    font-size: 2.1rem;
    line-height: 1.5;
    margin: 0;
    font-family: 'Manrope-Bold', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: capitalize;
    width: 50px;

    @media only screen and (min-width: 530px) {
      width: 200px;
   
    }

    @media only screen and (min-width: 1201px) {
     width: 100px;
  
    }

    @media only screen and (min-width: 1302px) {
      width: 150px;
   
    }

  }

  & .author {
    font-size: 1.8rem;
    line-height: 1;
    margin: 0;
    font-family: 'Manrope-Regular', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 140px;
    text-transform: capitalize;

  }

  & .controls_container {
    width: 35%;
    display: flex;
    justify-content: space-between;
    max-width: 200px;
    
  }

  & .button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    height: 4rem;
    width: 4rem;
    padding: 0;
  }

  & .icon {
    height: 4rem;
    width: 4rem;
  }

}
