*::selection {
  background-color: var(--main-accent-color);

}

body {
  background-color: white;

}

.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--main-principal-color);
  transform-origin: bottom;
  z-index: 9999;

}

.slide-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--main-principal-color);
  transform-origin: top;
  z-index: 9999;

}