* {
  cursor: none !important;
}

.custom-cursor {
  position: fixed;
  /* background-color: var(--main-accent-color); */
  /* background-color: #8400FF; */
  background-color: #fff;
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: width 0.2s ease, height 0.2s ease;
  z-index: 10000;mix-blend-mode: multiply;
  mix-blend-mode: difference;

  &::after {
    content: "";
    height: 2%;
    width: 2%;
    border: 1px solid #000;
    border-radius: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    background-color: #000;

  }

}

/* a, video {transition: ease all .5s;}

a:hover, video:hover {
  -webkit-box-shadow: 0px 0px 0px 1px var(--main-accent-color);
  -moz-box-shadow: 0px 0px 0px 1px var(--main-accent-color);
  box-shadow: 0px 0px 0px 1px var(--main-accent-color);

} */